html {
   margin: 0;
   padding: 0;
   border: 0;
   font-size: 100%;
   font: inherit;
   vertical-align: baseline;
}

body {
  background-color: white;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html, body, #root {
 height: 100vh;
 width: 100vw;
  margin: 0;
  padding: 0;
}

body{
  overflow: hidden;
}

h1{
  font-size: 32px;
  font-family: "muliBold";
}
h2{
  color: white;
}

p, button{
  font-family: "muliReg";
  font-size: 20px;
}
.App{
  display: flex;
  justify-content: center;
}
/* margin on root is actually not a great idea */
/* add margin to each page componenet */
*:focus {
    -webkit-tap-highlight-color: transparent;
    outline: none;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
}

@font-face {
  font-family: "muliReg";
  src: url("./assets/fonts/Muli-Regular.woff") format("woff")
}

@font-face {
  font-family: "muliBold";
  src: url("./assets/fonts/Muli-Bold.woff") format("woff")
}

@font-face {
font-family:"commuters-sans";
src:url("https://use.typekit.net/af/1fec5e/00000000000000003b9b2472/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/1fec5e/00000000000000003b9b2472/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/1fec5e/00000000000000003b9b2472/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
font-display:auto;font-style:normal;font-weight:400;
}
